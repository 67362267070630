import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/CategoryPageLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Release Notes`}</h1>
    <p>{`Discover the updates, improvements, and fixes made to the Engagement Cloud as part of the CoreMedia Experience Platform.`}</p>
    <p>{`Here, you can find a record of the ongoing evolution of the features included in Engagement Cloud, ensuring transparency and up to date information. Each update highlights major releases, bug fixes, feature enhancements, and any deprecations. The goal is to help you understand the impact of each change and facilitate a smoother transition between versions.`}</p>
    <p>{`The Engagement Cloud includes the following:`}</p>
    <ul>
      <li parentName="ul">{`Engagement Studio`}</li>
      <li parentName="ul">{`Contact Center and Analytics Studio`}</li>
      <li parentName="ul">{`Chat Bot and Automation Studio`}</li>
      <li parentName="ul">{`CoreMedia Tag`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      